import { documentConstants } from "../../constants";

const defaultState = {
  items: [],
  item: {},
  loadingItems: false,
  loadingItem: false,
  fetchedItems: false,
  fetchedItem: false,
  error: null
};

function normalData(arr) {
  if (!Array.isArray(arr)) return []

  return arr.map((elem, index) => Object.assign(elem, {
    index: (index + 1),
    whoCreated: typeof elem.whoCreated === 'object' ? elem.whoCreated.firstName + " " + elem.whoCreated.lastName : elem.whoCreated,
  }))
}

export const writeDownReducer = function (state = defaultState, action) {
  switch (action.type) {
    case documentConstants.GETALL_REQUEST_WRITEDOWN:
      return {
        ...state,
        item: {},
        loadingItems: true,
        loadingItem: false,
        fetchedItems: false,
        fetchedItem: false,
        error: null
      };
    case documentConstants.GETALL_SUCCESS_WRITEDOWN:
      return {
        ...state,
        items: normalData(action.response),
        loadingItems: false,
        fetchedItems: true,
      };
    case documentConstants.GETALL_FAILURE_WRITEDOWN:
      return {
        ...state,
        loadingItems: false,
        fetchedItems: false,
        error: action.error,
      };

    case documentConstants.GETBYID_REQUEST_WRITEDOWN:
      return {
        ...state,
        item: {},
        loadingItem: true,
        fetchedItem: false,
        error: null
      };
    case documentConstants.GETBYID_SUCCESS_WRITEDOWN:
      const existingItem = state.items.find(item => item.id === action.response.id && item.viewStatus?.status !== action.response?.viewStatus?.status);
      if (existingItem) {
        // Обновляем существующий элемент в общем списке
        return {
          ...state,
          items: state.items.map(item => {
            if (item.id !== action.response.id) {
              return item;
            }
            item.whoCreated = action.response?.whoCreated?.firstName + " " + action.response?.whoCreated?.lastName;
            item.viewStatus = action.response?.viewStatus;
            return item;
          }),
          item: action.response,
          loadingItem: false,
          fetchedItem: true,
        };
      } else {
        return {
          ...state,
          item: action.response,
          loadingItem: false,
          fetchedItem: true,
        };
      };
    case documentConstants.GETBYID_FAILURE_WRITEDOWN:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case documentConstants.CREATE_REQUEST_WRITEDOWN:
      return {
        ...state,
        item: {},
        loadingItem: true,
        fetchedItem: false,
        error: null
      };
    case documentConstants.CREATE_SUCCESS_WRITEDOWN:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: true,
        items: normalData(state.items.concat(action.response)),
        item: action.response,
      };
    case documentConstants.CREATE_FAILURE_WRITEDOWN:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case documentConstants.UPDATE_REQUEST_WRITEDOWN:
      return {
        ...state,
        loadingItem: true,
        fetchedItem: false,
        error: null
      };
    case documentConstants.UPDATE_SUCCESS_WRITEDOWN:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: true,
        item: action.response,
        items: normalData(state.items.map((elem) => {
          if (elem.id === action.response.id) return Object.assign(elem, { ...action.response })
          return elem
        }))
      };
    case documentConstants.UPDATE_FAILURE_WRITEDOWN:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case documentConstants.ARCHIVE_REQUEST_WRITEDOWN:
      return {
        ...state,
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case documentConstants.ARCHIVE_SUCCESS_WRITEDOWN:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        items: normalData(state.items.map((elem) => {
          if (elem.id === action.id) return Object.assign(elem, { ...action.response, isArchive: !elem.isArchive })
          return elem
        }))
      };
    case documentConstants.ARCHIVE_FAILURE_WRITEDOWN:
      return {
        ...state,
        loadingItem: false,
        error: action.error,
      };

    case "RESET_ITEM_WRITEDOWN":
      return {
        ...state,
        fetchedItem: false,
        loadingItem: false,
        item: {}
      }

    case "RESET_ITEMS_WRITEDOWN":
      return {
        ...state,
        fetchedItems: false,
        loadingItems: false,
        items: [],
        error: null
      }
    default:
      return state;
  }
}