import React from "react";
import PropTypes from "prop-types";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { EmptyRow } from "../../components";
import { RowClassName } from "../../helpers/RowClassName";

const gridStyle = { minHeight: "100%", borderRadius: 4 };

const i18n = Object.assign({}, ReactDataGrid.defaultProps.i18n, {
  pageText: "Страница ",
  ofText: " из ",
  perPageText: "Элементов на странице",
  showingText: "Показано ",
  clearAll: "Очистить все",
  clear: "Очистить",
  showFilteringRow: "Показать фильтры",
  hideFilteringRow: "Скрыть фильтры",
  enable: "Включить",
  disable: "Выключить",
  sortAsc: "По возрастанию",
  sortDesc: "По убыванию",
  unsort: "Без сортировки",
  group: "Group",
  ungroup: "Ungroup",
  lockStart: "Lock start",
  lockEnd: "Lock end",
  unlock: "Unlock",
  columns: "Колонки",
  contains: "Содержит",
  startsWith: "Начинается с",
  endsWith: "Заканчивается на",
  notContains: "Не содержит",
  inlist: "В списке",
  notinlist: "Не в списке",
  neq: "Не эквивалентно",
  inrange: "В диапазоне",
  notinrange: "Вне диапазона",
  eq: "Эквивалентно",
  notEmpty: "Не пусто",
  empty: "Пусто",
  lt: "Меньше чем",
  lte: "Меньше чем или равно",
  gt: "Больше чем",
  gte: "Больше чем или равно",
  before: "До",
  beforeOrOn: "До или на",
  afterOrOn: "После или на",
  after: "После",
  start: "Начало",
  end: "Конец",
  dragHeaderToGroup: "Переместите заголовок для группировки",
  "calendar.todayButtonText": "Сегодня",
  "calendar.clearButtonText": "Очистить",
  "calendar.okButtonText": "OK",
  "calendar.cancelButtonText": "Отмена",
});



const pleaseWait = <b>Идет обновление записей...</b>;

const GridTable = (props) => {
  const { items, fields, loading, filter, filtering, onRowClick, isDocument = false, groups, rowStyle, columnStyle, useRowAlignCenter } = props;

  const scrollProps = Object.assign({}, ReactDataGrid.defaultProps.scrollProps, {
    autoHide: false,
    alwaysShowTrack: true,
  });

  const defaultSortInfo = { name: 'id', type: 'number', dir: -1 };

  const setRowStyle = () => {
    if (useRowAlignCenter) {
      return {
        textAlign: "center",
      };
    }
    return {};
  }
  
  return (
    <ReactDataGrid
      idProperty="id"
      style={props.minimalHeight ? { ...gridStyle, minHeight: props.minimalHeight } : gridStyle}
      i18n={i18n}
      groups={groups}
      showCellBorders={typeof props.showCellBorders === "string" ? props.showCellBorders : true}
      defaultSortInfo={isDocument ? defaultSortInfo : null}
      columns={fields}
      dataSource={items}
      rowClassName={RowClassName}
      renderRowContextMenu={props.renderRowContextMenu ? props.renderRowContextMenu : undefined}
      rowHeight={props.rowHeight ? props.rowHeight : 40}
      loading={loading}
      editStartEvent="click"
      loadingText={pleaseWait}
      emptyText={<EmptyRow />}
      pagination={props.pagination !== false ? true : false}
      defaultLimit={50}
      defaultFilterValue={filter}
      enableFiltering={filtering ? filtering : false}
      showColumnMenuLockOptions={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuFilterOptions={false}
      showFilteringRow={false}
      showColumnMenuToolOnHover={false}
      scrollProps={scrollProps}
      onCellClick={onRowClick}
      enableKeyboardNavigation={false}
      rowStyle={setRowStyle}
    //preventRowSelectionOnClickWithMouseMove={true}
    //stickyGroupRows={false}
    //nativeScroll={true}
    />
  );
};

GridTable.propTypes = {
  items: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
};

export default React.memo(GridTable);
